import * as React from "react"

import Seo from "../components/v1/seo"
import Layout from "../components/v2/Layout/layout2"
import { Container, Image } from "semantic-ui-react"
import NotFoundImage from "../images/404.png"
import styled from "styled-components"

const NotFoundPageWrapper = styled.div`
  .info_text{
    margin: 0 2rem;
    font-weight: 500;
}
.info_text h1 {
    font-size: 4rem;
    margin: 0;
    color:rgb(61, 61, 61);
  }
.info_text h3{
    margin: 35px 0;
}
.info_text h1 span{
    color: #f1592a;
}
.ui.button.home-btn{
  border-radius: 5000px;
  background: #fff;
  border: 1px solid #f1592a;
  color: #f1592a;
  padding: 10px;
  font-size: 14px;
  text-align: center;
  align-items: center;
  justify-content: center;
  display: flex;
  min-width: 130px;
  margin-right: 30px;
  font-weight: bold;
  transition: 0.3s;
  height: 42px;
  &:hover{
    background-color: #f1592a;
    color: #fff;
  }

}

@media only screen and (max-width: 1200px) {
    .info_text h1 {
        font-size: 3rem;
      }
  }

@media screen and (max-width:1000px){
    .info_text h1{
        font-size: 2rem;
    }
}
@media screen and (max-width:768px){
    .info_text {
      text-align: center;
    }
}
@media screen and (max-width:650px){
    .info_text h1{
        font-size: 1rem;
    }
}
@media screen and (max-width:500px){
    .info_text h1{
        font-size: 1.1rem;
    }
    .info_text h3{
        font-size: 1.1rem;
    }
}

`

const NotFoundPage = () => (
  <Layout>
    <NotFoundPageWrapper>
      <Container style={{ margin: '50px 0' }}>
        <Image centered size={"big"} src={NotFoundImage} alt="Logo" />
        <div className="info_text">
          <h1><span>Oops</span>, this page may be on a</h1>
          <h1>truck being delivered to its buyer.</h1>
          <h3 style={{ fontWeight: 'normal' }}>The page you are looking for might have been removed, had its name changed, or is temporarily unavailable</h3>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <button className="ui button home-btn" style={{ width: '13rem', height: '3.5rem' }}>Go Back Home</button>
          </div>
        </div>
      </Container>
    </NotFoundPageWrapper>
  </Layout>
)


export default NotFoundPage
